
import Vue from "vue"
import { mapGetters } from "vuex"
import type { MetaInfo } from "vue-meta"
import type { ExhibitionEvent } from "@/types/api/exhibition"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default Vue.extend({
  data: () => ({
    events: [] as ExhibitionEvent[],
    step: 0,
    onScreen: 2,
    interval: 3,
    timer: undefined as Timer,
  }),

  async fetch () {
    await this.fetchEvents()
  },

  head (): MetaInfo {
    // @ts-ignore
    return { title: this.getTitle(this.title) }
  },

  computed: {
    ...mapGetters("app", ["getTitle"]),
  },

  mounted () {
    this.timer = setInterval(this.autoSwitch, this.interval * 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },

  methods: {
    async fetchEvents () {
      const [res, err] = await this.$api.exhibition.getEvents({
        params: {
          "filter[start]": Date.now(),
          "filter[listedOnIndexPage]": true,
          "sort[start]": "asc",
          "paginator[limit]": 100,
        },
      })

      if (err) return console.error(err)
      if (!res) return

      this.events = this.$api.helpers.getList(res)
    },

    switchSlide (direction = "right") {
      clearInterval(this.timer)
      if (direction === "right") {
        if (this.step < 1) return
        this.step--
      } else {
        this.onScreen = window.innerWidth >= breakpoints.xs ? 2 : 1
        if (this.step + this.onScreen >= this.events.length) return
        this.step++
      }
    },

    autoSwitch () {
      if (this.step + this.onScreen >= this.events.length) this.step = 0
      else this.step++
    },
  },
})
